<template>
  <div>
    <div class="d-flex flex-column flex-xl-row align-items-end">
      <div class="w-100 w-xl-50 ">


        <v-observer tag="form" class="animated fadeIn bg-light py-2 px-2 " @submit.prevent="fnResetPagination()">

          <div class="row mb-1 align-items-end">
            <div class="ml-auto col-auto">
              <div class="form-group mb-0 ">
                <div class="input-group ">
                  <input type="text" class="form-control" placeholder="Product name"
                    v-model="pagination.name " @change="fnResetPagination()">
                  <div class="input-group-append">
                    <button class="btn btn-success" type="button"> <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p class="text-right m-0" v-text=" $t('general.form.coincidences') + ': ' +  coincidences  ">
            <span></span>
            <span> </span>
          </p>
        </v-observer>

        <div class="produsct-list border ">

          <table class="table table-sm table-striped table-hover"
            v-if="!loading.products && (productsList.length > 0) && (coincidences > 0) ">
            <thead>
              <tr>
              </tr>
            </thead>
            <tbody>
              <tr v-for=" (product,index) in productsList " :key="index"
                v-show=" productsSelectedList.findIndex( tempProduct => (tempProduct.id == product.id) )  === -1  ">
                <td>

                  <div class="row align-items-center justify-content-between ">
                    <div class="col-9 col-md ">
                      <div class="row align-items-center">
                        <div class="col-2 col-md-auto">
                          <img v-image="product.imageUrl" alt="" class="img-contain-30">
                        </div>
                        <div class="col-10 col-md ">
                          <p class="mb-0">
                            <span v-text=" product.name ? product.name : product.id "></span>
                          </p>
                          <p class="mb-0">
                            <strong v-text=" $t('general.form.price') + ': ' "></strong>
                            <span
                              :class=" (( newDiscount.amount > 0 && newDiscount.amount <= 100)) ? 'text-line-through text-muted' : '' "
                              v-text="fnFormatPrice( product.price , product.currency ) "></span>

                            <span v-show="( newDiscount.amount > 0 && newDiscount.amount <= 100)"
                              class="animated fadeIn">
                              <span v-show="( newDiscount.amount > 0 && newDiscount.amount <= 100)"> - </span>
                              <strong class="text-success"
                                v-text=" fnFormatPrice(( product.price - ( newDiscount.amount * product.price / 100  )), product.currency ) "></strong>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-3 col-md-auto ">
                      <button class="btn btn-light btn-sm" type="button" v-text=" $t('general.button.add') "
                        @click="fnAddProduct(product)"></button>
                    </div>
                  </div>

                </td>
              </tr>
            </tbody>
          </table>

          <div class="d-flex align-items-center justify-content-center h-100" v-else>
            <no-data :dataMessage="$t('noDataMessages.noInformaton')" :showBtn="false" :isLoading="loading.products" sm>
            </no-data>
          </div>
        </div>

      </div>

      <div class="w-100 w-xl-50">
        <div class="animated fadeIn">
          <div class="produsct-list border ">
            <table class="table table-sm table-striped table-hover " v-if=" productsSelectedList.length > 0 ">
              <thead>
                <tr>
                </tr>
              </thead>
              <tbody>
                <tr v-for=" (product,index) in  productsSelectedList " :key="index">
                  <td>
                    <div class="row align-items-center justify-content-between ">
                      <div class="col-9 col-md ">
                        <div class="row align-items-center">
                          <div class="col-2 col-md-auto">
                            <img v-image="product.imageUrl" alt="" class="img-contain-30">
                          </div>
                          <div class="col-10 col-md ">
                            <p class="mb-0">
                              <span v-text=" product.name  ? product.name : product.id  "></span>
                            </p>
                            <p class="mb-0">
                              <strong v-text=" $t('general.form.price') + ': ' "></strong>
                              <span
                                :class=" (( newDiscount.amount > 0 && newDiscount.amount <= 100)) ? 'text-line-through text-muted' : ''  "
                                v-text="fnFormatPrice(  product.price ,  product.currency )  "></span>

                              <span v-show="( newDiscount.amount > 0 && newDiscount.amount <= 100)"
                                class="animated fadeIn">
                                <span v-show="( newDiscount.amount > 0 && newDiscount.amount <= 100)"> - </span>
                                <strong class="text-success"
                                  v-text=" fnFormatPrice( ( product.price - ( newDiscount.amount * product.price / 100  ))  , product.currency ) "></strong>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 col-md-auto ">
                        <button class="btn btn-light btn-sm" type="button" v-text=" $t('general.button.remove') "
                          @click="fnRemoveProduct(product)"></button>
                      </div>
                    </div>

                  </td>
                </tr>
              </tbody>
            </table>

            <div class="d-flex align-items-center justify-content-center h-100" v-else>
              <no-data :dataMessage="$t('dashboard.discounts.addProducts')" :showBtn="false" :isLoading="false" sm>
              </no-data>
            </div>

          </div>

        </div>
      </div>
    </div>
    <div class="d-flex w-100">
      <b-pagination v-model="pagination.page" :total-rows="pagination.total" :per-page="pagination.limit"
        class="w-50 animation fadeIn" @input="fnApiGetProducts" align="fill" :first-text="$t('general.button.first')" :prev-text="$t('general.button.previous')"
        :next-text="$t('general.button.next')" :last-text="$t('general.button.last')"
        v-show=" pagination.total > 0 && ( (pagination.total / pagination.limit) > 1 ) ">
      </b-pagination>
    </div>

  </div>
</template>

<script>
  import {
    mapState,
    mapActions,
    mapMutations,
    mapGetters1
  } from 'vuex';

  export default {
    props: {
      storeId: {
        type: String,
        required: true,
      },
      discountProductsList: {
        type: Array,
        required: false,
      },
    },
    computed: {
      ...mapState('discounts', ['newDiscount']),
    },
    data() {
      return {
        productsSelectedList: [],
        productsList: [],
        loading: {
          products: true,
        },
        pagination: {
          storeId: null,
          name: null,
          active: null,
          page: 1,
          limit: 50,
          total: null,
          improvements: null,
        },
        coincidences: 0,
      }
    },
    watch: {
      productsSelectedList() {
        let tempList = [];
        this.productsSelectedList.map(product => {
          tempList.push({
            id: product.id,
          });
        });
        this.$emit('set-products', tempList);
        this.fnCountCoincidences();
      },
      storeId() {
        if (this.storeId) {
          this.productsSelectedList = [];
          this.fnFirstPagination();
        }
      },
    },
    methods: {
      // ...mapActions('discounts', ['fnApiGetProducts']),
      fnCountCoincidences() {
        if (this.pagination.name == null) {
          this.coincidences = (this.pagination.total - this.productsSelectedList.length);
        } else {
          let count = 0;
          this.productsSelectedList.map(product => {
            if (product.name.toLowerCase().search(this.pagination.name.toLowerCase()) != -1) {
              count++
            };
          })
          this.coincidences = this.pagination.total - count;
        }
      },

      fnAddProduct(product) {
        this.productsSelectedList.push(product);
      },
      fnRemoveProduct(product) {
        this.productsSelectedList.splice(this.productsSelectedList.findIndex(tempProduct => tempProduct.id == product
          .id), 1);
      },

      fnResetData() {
        this.productsSelectedList = [];
        this.fnFirstPagination();
      },
      fnFirstPagination() {
        this.pagination.page = 1;
        this.pagination.limit = this.CONST.PAGINATION[0];
        this.pagination.storeId = this.storeId || null;
        this.pagination.name = null;
        this.fnApiGetProducts();
        this.fnApiGetCount();
      },
      async fnResetPagination() {
        this.pagination.page = 1;
        this.fnApiGetProducts();
        this.fnApiGetCount();
      },

      async fnApiGetProducts() {
        this.loading.products = true;
        let {
          name,
          storeId,
          active,
          limit,
          improvements,
          page
        } = this.$lodash.clone(this.pagination);
        let tempPagination = {
          page,
          limit
        };
        if (name != null && name != '') {
          tempPagination.name = name
        };
        if (storeId != null && storeId != '') {
          tempPagination.store_id = storeId
        };
        if (active !== null && active !== '') {
          tempPagination.active = active
        };
        if (improvements !== null && improvements !== '') {
          tempPagination.improvements = improvements
        };
        await axios.get('products', {
          params: tempPagination
        }).then(async (response) => {

          if (response.data.length > 0) {

            response.data = response.data.map((product) => {
              product.show = false;
              product.selected = false;

              if (product.variants) {
                // product.variantsList = [];
              } else {
                product.variantsList = [];
              }
              return product;
            });
          }
          this.productsList = response.data;
          this.fnCountCoincidences()

        }).catch(error => {});
        this.loading.products = false;
      },

      fnApiGetCount() {
        let {
          name,
          storeId,
          improvements,
          active
        } = this.$lodash.cloneDeep(this.pagination);
        let tempPagination = {};
        if (name != null && name != '') {
          tempPagination.name = name
        };
        if (storeId != null && storeId != '') {
          tempPagination.store_id = storeId
        };
        if (active !== null && active !== '') {
          tempPagination.active = active
        };
        if (improvements !== null && improvements !== '') {
          tempPagination.improvements = improvements
        };

        axios.get('products/count', {
          params: tempPagination
        }).then(async (response) => {
          this.pagination.total = response.data.count;
          this.fnCountCoincidences()
        }).catch(error => {});
      },

    },
    mounted() {
      if (this.discountProductsList && this.discountProductsList.length > 0) {
        this.productsSelectedList = [...this.discountProductsList];
      } else {
        this.productsSelectedList = [];
      }
      this.fnFirstPagination();
    },
    updated() {}
  }
</script>

<style>
  .produsct-list {
    height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }
</style>